<template>
  <div class="wrap">
    <section class="contract">
      <h1 style="margin-top: 0">Umowa o&nbsp;dzieło <span class="contract-number" contenteditable="true">{{ contract.number || '...........................' }}</span><br>
      <small>z przekazaniem praw autorskich</small></h1>

      <p>Zawarta w&nbsp;dniu <span class="contract-date" contenteditable="true">{{ contract.date || '...........................' }}</span> w&nbsp;{{ companyObject.city }} pomiędzy:</p>

      <ol>
        <li>{{ company }} zwanym dalej w&nbsp;niniejszej Umowie „Zamawiającym”, a</li>
        <li><span class="contract-name" contenteditable="true">{{ contract.name || '...........................' }}</span>, {{ male ? 'legitymującym' : 'legitymującą'}} się dowodem osobistym seria nr&nbsp;<span class="contract-id-number" contenteditable="true">{{ contract.idnumber || '...........................' }}</span> wydanym przez <span class="contract-id-who" contenteditable="true">{{ contract.idwho || '...........................' }}</span> {{ male ? 'zamieszkałym' : 'zamieszkałą'}} w&nbsp;<span class="contract-address" contenteditable="true">{{ contract.address || '...........................' }}</span>, PESEL <span class="contract-pesel" contenteditable="true">{{ contract.pesel || '...........................' }}</span> {{ male ? 'zwanym' : 'zwaną'}} dalej w&nbsp;niniejszej Umowie „Wykonawcą”.</li>
      </ol>

      <h2>§1 PRZEDMIOT UMOWY</h2>
      <ol>
        <li>Na podstawie niniejszej Umowy, Wykonawca zobowiązuje się do&nbsp;wykonania <span class="contract-terms" contenteditable="true">{{ contract.terms || '...........................' }}</span>.</li>
        <li>Wykonawca oświadcza, że posiada niezbędne umiejętności, wiedzę, środki, sprzęt i&nbsp;doświadczenie do&nbsp;wykonania prac będących przedmiotem umowy i&nbsp;zobowiązuje się je wykonać z&nbsp;należytą starannością oraz aktualnym poziomem wiedzy i&nbsp;techniki. </li>
      </ol>

      <h2>§2 TERMIN i&nbsp;SPOSÓB WYKONANIA UMOWY</h2>
      <ol>
        <li>Strony zgodnie ustalają termin rozpoczęcia prac na&nbsp;dzień <span class="contract-start" contenteditable="true">{{ contract.start || '...........................' }}</span>, a&nbsp;termin zakończenia prac ustalają na&nbsp;dzień <span class="contract-end" contenteditable="true">{{ contract.end || '...........................' }}</span>.</li>
        <li>Przyjmuje się, że Wykonawca wykona umowę samodzielnie. </li>
        <li>W przypadku gdy Wykonawca przedstawi Zamawiającemu dzieło niespełniające ustalonych wymogów, Zamawiający wyznaczy Wykonawcy odpowiedni termin, nie krótszy niż 7 dni, na&nbsp;usunięcie ujawnionych nieprawidłowości lub błędów. </li>
        <li>W przypadku gdy Zamawiający w&nbsp;trakcie przebiegu prac, dostarcza Wykonawcy nowe dane, przez co dzieło wymaga ponownego opracowania, Wykonawca wyznaczy odpowiedni termin i&nbsp;kwotę wynagrodzenia za dodatkowo wykonane czynności, a&nbsp;umowa zostaje zmieniona. </li>
      </ol>

      <h2>§3 PRZENIESIENIE PRAW AUTORSKICH</h2>
      <ol>
        <li>Wyżej wymienione prace są przedmiotem prawa autorskiego i&nbsp;praw pokrewnych.</li>
        <li>Z dniem akceptacji dzieła przez Zamawiającego, autorskie prawa majątkowe przechodzą na&nbsp;Zamawiającego.</li>
        <li>Wykonawca przekaże Zamawiającemu dzieło w&nbsp;formie pliku elektronicznego.</li>
      </ol>

      <h2>§4 PRAWA i&nbsp;OBOWIĄZKI STRON</h2>
      <ol>
        <li>Zamawiający zobowiązuje się przed rozpoczęciem pracy przez Wykonawcę dostarczyć Wykonawcy wszystkie materiały potrzebne do&nbsp;wykonania przedmiotowej umowy. </li>
        <li>W szczególnym przypadku na&nbsp;uzasadnione żądanie Wykonawcy Zamawiający niezwłocznie przekaże dodatkowe materiały potrzebne do&nbsp;wykonania Strony.</li>
        <li>Jeżeli materiały dostarczone przez Zamawiającego nie nadają się do&nbsp;prawidłowego wykonania umowy albo jeżeli zajdą inne okoliczności, które mogą przeszkodzić w&nbsp;wykonaniu umowy, Wykonawca zobowiązuje się niezwłocznie zawiadomić o&nbsp;tym Zamawiającego. </li>
        <li>W przypadku niedostarczenia przez Wykonawcę dzieła w&nbsp;terminie wskazanym w&nbsp;§ 2, Zamawiający wyznaczy dodatkowy termin, po upływie którego przysługuje mu prawo odstąpienia od&nbsp;niniejszej umowy ze skutkiem natychmiastowym.</li>
        <li>Jeżeli wykonanie dzieła według Projektu okaże się niecelowe bądź jeżeli Zamawiający z&nbsp;przyczyn sobie wiadomych zrezygnuje z&nbsp;dalszego wykonywania umowy, Zamawiający będzie mógł odstąpić od&nbsp;niniejszej Umowy za zapłatą zaliczki wysokości 50% wynagrodzenia.</li>
      </ol>

      <h2>§5 WYNAGRODZENIE</h2>
      <ol>
        <li>Za wykonanie przedmiotu niniejszej umowy Wykonawca otrzyma wynagrodzenie w&nbsp;wysokości <span class="contract-quota" contenteditable="true">{{ contract.quota || '...........................' }}</span>zł brutto (słownie <span class="contract-wordly" contenteditable="true">{{ wordly || '...........................' }}</span>).</li>
        <li v-if="contract.payment == 'transfer'">Wynagrodzenie zostanie przekazane na&nbsp;konto bankowe przyjmującego (bank i&nbsp;nr konta: <span class="contract-bank" contenteditable="true">{{ contract.bank || '...........................' }}</span>).</li>
        <li v-if="contract.payment == 'cash'">Wynagrodzenie zostanie wypłacone zgodnie z&nbsp;przedstawionym rachunkiem w&nbsp;gotówce w&nbsp;dniu akceptacji dzieła.</li>        <li class="dependent">Zamawiający zapłaci Wykonawcy w&nbsp;terminie 14 dni od&nbsp;podpisania niniejszej umowy zaliczkę w&nbsp;wysokości 35% wynagrodzenia o&nbsp;którą pomniejszone zostanie wynagrodzenie końcowe.</li>
        <li>Wykonawca nie może żądać podwyższenia wynagrodzenia, jeżeli wykonał prace dodatkowe bez uzyskania zgody Zamawiającego.</li>
      </ol>

      <h2>§6 POSTANOWIENIA KOŃCOWE </h2>
      <ol>
        <li>Sądem właściwym do&nbsp;rozpatrywania sporów wynikłych ze stosowania niniejszej umowy jest sąd właściwy dla&nbsp;miejsca siedziby Wykonawcy.</li>
        <li>Wszelkie zmiany umowy mogą nastąpić jedynie za zgodą stron w&nbsp;formie pisemnej pod rygorem nieważności.</li>
        <li>Umowę sporządzono w&nbsp;dwóch jednobrzmiących egzemplarzach, po jednym dla&nbsp;każdej ze Stron.</li>
        <li>Wymienione w&nbsp;umowie załączniki stanowią integralną jej część.</li>
        <li>W sprawach nieuregulowanych w&nbsp;niniejszej umowie mają zastosowanie przepisy Kodeksu Cywilnego oraz przepisy Ustawy z&nbsp;dnia 4 lutego 1994 r. o&nbsp;prawie autorskim i&nbsp;prawach pokrewnych (Dz. U. 1994 nr&nbsp;24 poz. 83 z&nbsp;późn. zm.). </li>
      </ol>

      <div class="contract-bottom row">
        <div class="col-xs-6 text-center">
          <hr>
          Zamawiający
        </div>
        <div class="col-xs-6 text-center">
          <hr>
          Wykonawca
        </div>
      </div>
    </section>

    <section class="contract">
      <div class="text-right">{{ companyObject.city }}, <span class="contract-end" contenteditable="true">{{ contract.end || '...........................' }}</span></div>

      <h1>Rachunek <span class="contract-number" contenteditable="true">{{ contract.number || '...........................' }}</span></h1>

      <p>Dotyczy umowy nr&nbsp;<span class="contract-number" contenteditable="true">{{ contract.number || '...........................' }}</span> zawartej w&nbsp;dniu <span class="contract-date" contenteditable="true">{{ contract.date || '...........................' }}</span> w&nbsp;{{ companyObject.city }} pomiędzy:</p>

      <ol>
        <li>{{ company }} zwanym dalej w&nbsp;niniejszej Umowie „Zamawiającym”, a</li>
        <li><span class="contract-name" contenteditable="true">{{ contract.name || '...........................' }}</span>, {{ male ? 'legitymującym' : 'legitymującą'}} się dowodem osobistym seria nr&nbsp;<span class="contract-id-number" contenteditable="true">{{ contract.idnumber || '...........................' }}</span> wydanym przez <span class="contract-id-who" contenteditable="true">{{ contract.idwho || '...........................' }}</span> {{ male ? 'zamieszkałym' : 'zamieszkałą'}} w&nbsp;<span class="contract-address" contenteditable="true">{{ contract.address || '...........................' }}</span>, PESEL <span class="contract-pesel" contenteditable="true">{{ contract.pesel || '...........................' }}</span> {{ male ? 'zwanym' : 'zwaną'}} dalej w&nbsp;niniejszej Umowie „Wykonawcą”.</li>
      </ol>

      <p class="lead">Praca została wykonana zgodnie z&nbsp;warunkami umowy.</p>

      <ol>
        <li>Kwota wynagrodzenia brutto: <code class="contract-quota" contenteditable="true">{{ contract.quota || '...........................' }}</code>&nbsp;zł.</li>
        <li>Kwota uzysku: <code class="contract-quota-half" contenteditable="true">{{ quotaHalf }}</code>&nbsp;zł.</li>
        <li>Kwota do&nbsp;opodatkowania: <code class="contract-quota-half" contenteditable="true">{{ quotaHalf }}</code>&nbsp;zł</li>
        <li>Podatek od&nbsp;wynagrodzenia: <code class="contract-quota-tax" contenteditable="true">{{ quotaTax }}</code>&nbsp;zł</li>
        <li>Kwota do&nbsp;wypłaty: <code class="contract-to-pay" contenteditable="true">{{ quotaPay }}</code>&nbsp;zł</li>
      </ol>

      <div class="contract-bottom row">
        <div class="col-xs-6 text-center">
          <hr>
          Zamawiający
        </div>
        <div class="col-xs-6 text-center">
          <hr>
          Wykonawca
        </div>
      </div>
    </section>

    <section class="contract">
      <div class="text-right">{{ companyObject.city }}, <span class="contract-end" contenteditable="true">{{ contract.end || '...........................' }}</span></div>
      <h1>Protokół odbioru dzieła</h1>

      <p>Dotyczy przekazania/odbioru umowy o&nbsp;dzieło nr&nbsp;<span class="contract-number" contenteditable="true">{{ contract.number || '...........................' }}</span> zawartej w&nbsp;dniu <span class="contract-date" contenteditable="true">{{ contract.date || '...........................' }}</span> w&nbsp;{{ companyObject.city }} pomiędzy:</p>

      <ol>
        <li>{{ company }} zwanym dalej w&nbsp;niniejszej Umowie „Zamawiającym”, a</li>
        <li><span class="contract-name" contenteditable="true">{{ contract.name || '...........................' }}</span>, {{ male ? 'legitymującym' : 'legitymującą'}} się dowodem osobistym seria nr&nbsp;<span class="contract-id-number" contenteditable="true">{{ contract.idnumber || '...........................' }}</span> wydanym przez <span class="contract-id-who" contenteditable="true">{{ contract.idwho || '...........................' }}</span> {{ male ? 'zamieszkałym' : 'zamieszkałą'}} w&nbsp;<span class="contract-address" contenteditable="true">{{ contract.address || '...........................' }}</span>, PESEL <span class="contract-pesel" contenteditable="true">{{ contract.pesel || '...........................' }}</span> {{ male ? 'zwanym' : 'zwaną'}} dalej w&nbsp;niniejszej Umowie „Wykonawcą”.</li>
      </ol>

      <p>Dzieło polegało na&nbsp;wykonaniu <span class="contract-terms" contenteditable="true">{{ contract.terms || '...........................' }}</span>.</p>

      <p class="lead">Zamawiający postanawia: przyjąć dzieło bez zastrzeżeń i&nbsp;wypłacić 100% wynagrodzenia przewidzianego w&nbsp;umowie.</p>

      <div class="contract-bottom row">
        <div class="col-xs-6 text-center">
          <hr>
          Zamawiający
        </div>
        <div class="col-xs-6 text-center">
          <hr>
          Wykonawca
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import textMixins from '@/mixins/texts-mixin';

export default {
  name: 'DefaultText',
  props: ['contract', 'company', 'companyObject'],
  mixins: [textMixins],
};
</script>
