import slownie from '../js/slownie';

export default {
  props: ['contract', 'company', 'companyObject'],
  computed: {
    quotaHalf() {
      const val = parseFloat(this.contract.quota);
      if (val) {
        const result = val * 0.5;

        return result.toFixed(2);
      }

      return '...........................';
    },
    quotaTax() {
      const result = this.quotaHalf * 0.12;

      if (result) {
        return result.toFixed(0);
      }

      return '...........................';
    },
    quotaPay() {
      const val = parseFloat(this.contract.quota);

      if (val) {
        const result = val - this.quotaTax;

        return result.toFixed(2);
      }
      return '...........................';
    },
    male() {
      return this.contract.gender === 'male';
    },
    wordly() {
      const v = this.contract.quota;

      if (Number.isNaN(v) || !v) {
        return '..............................';
      }
      return `${slownie(v)} zł`;
    },
  },
};
