<template>
  <div>
    <ContractForm
      :contract="contract"
      @clear="clearForm"
    />
    <DefaultText
      :contract="contract"
      :company="company"
      :companyObject="companyObject"
    />
  </div>
</template>

<script>
import viewsMixin from '@/mixins/views-mixin';
import ContractForm from '@/components/ContractForm.vue';
import DefaultText from '@/components/DefaultText.vue';

export default {
  name: 'Home',
  components: {
    ContractForm,
    DefaultText,
  },
  mixins: [viewsMixin],
};
</script>
