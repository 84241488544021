import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/artist',
    name: 'Artist',
    component: () => import(/* webpackChunkName: "artist" */ '../views/Artist.vue'),
  },
  {
    path: '/foreigner',
    name: 'Foreigner',
    component: () => import(/* webpackChunkName: "artist" */ '../views/Foreigner.vue'),
  },
  {
    path: '/invoice',
    name: 'ArtistInvoice',
    component: () => import(/* webpackChunkName: "ArtistInvoice" */ '../views/ArtistInvoice.vue'),
  },
  {
    path: '/concert',
    name: 'Concert',
    component: () => import(/* webpackChunkName: "Concert" */ '../views/Concert.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
  },
  {
    path: '/author',
    name: 'Author',
    component: () => import(/* webpackChunkName: "author" */ '../views/Author.vue'),
  },
  {
    path: '/person-26y',
    name: 'Person26y',
    component: () => import(/* webpackChunkName: "Person26y" */ '../views/Person26y.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
