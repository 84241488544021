export default {
  data: () => ({
    contract: {
      number: '',
      data: '',
      name: '',
      idnumber: '',
      idwho: '',
      address: '',
      pesel: '',
      start: '',
      end: '',
      quota: '',
      bank: '',
      terms: '',
      payment: 'transfer',
      gender: 'male',
      image: false,
    },
    company: '',
    companyObject: {},
    currentCompany: null,
    allCompanies: [],
  }),
  created() {
    const searchParams = new URLSearchParams(this.$route.query);

    searchParams.forEach((value, key) => {
      this.contract[key] = value;
    });

    const currentCompany = localStorage.getItem('currentCompany');
    const allCompanies = localStorage.getItem('allCompanies');

    if (currentCompany && allCompanies) {
      this.currentCompany = currentCompany;
      this.allCompanies = {
        ...this.allCompanies,
        ...JSON.parse(allCompanies),
      };
    }

    if (this.currentCompany !== null && Object.prototype.hasOwnProperty.call(this.allCompanies, this.currentCompany)) {
      this.companyObject = {
        ...this.companyObject,
        ...this.allCompanies[this.currentCompany],
      };

      this.company = this.toStr(this.companyObject);
    }
  },
  methods: {
    toStr({
      name,
      street,
      zipCode,
      city,
      nip,
    }) {
      const address = `${street}, ${zipCode} ${city}`;
      return `${name}, ${address}, NIP: ${nip}`;
    },
    clearForm() {
      this.contract = {
        number: '',
        data: '',
        name: '',
        idnumber: '',
        idwho: '',
        address: '',
        pesel: '',
        start: '',
        end: '',
        quota: '',
        bank: '',
        terms: '',
        payment: 'transfer',
        gender: 'male',
      };
    },
  },
};
